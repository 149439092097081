import { useEffect, useMemo, useState } from 'react';

import type { TSearchConfigAPIResponse } from '@atlassian/search-client';
import { getSearchConfigV2 } from '@atlassian/search-client';
import type { ExperimentExperience } from '@atlassian/search-experiment';
import { dynamicExperimentsSchema, mapDynamicExperiments } from '@atlassian/search-experiment';
import { Ati } from '@atlassian/ari';

import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';
import { fg } from '@confluence/feature-gating';

const HELLO_CLOUD_ID = 'a436116f-02ce-4520-8fbb-7301462a1674';
const EXPIRATION_TIME = 1000 * 60 * 60 * 24;

type useDynamicBackendExperimentArgs = {
	cloudId: string;
	entities: string[];
	userId?: string;
	experience?: ExperimentExperience;
};

export const useDynamicBackendExperiment = ({
	cloudId,
	entities,
	userId,
	experience,
}: useDynamicBackendExperimentArgs) => {
	const [searchConfig, setSearchConfig] = useState<TSearchConfigAPIResponse>();

	useEffect(() => {
		// Do nothing if feature gate is off
		if (!fg('confluence_search-scalable-statsig-layers')) {
			return;
		}

		if (!cloudId) {
			return;
		}

		getSearchConfigV2({
			cloudId,
			userId,
			onlyRefreshIfExpired: true,
			expiration: EXPIRATION_TIME,
		})
			.then((result) => {
				setSearchConfig(result);
			})
			.catch(() => {
				// TODO: Error handling with Confluence's sentry client
				setSearchConfig({
					siteMetadata: [],
					resources: [],
					intercomHmac: '',
				});
			});
	}, [cloudId, userId]);

	return useMemo(() => {
		// Return undefined if feature gate is off
		if (!fg('confluence_search-scalable-statsig-layers')) {
			return undefined;
		}

		// Do not return dynamic experiments if search config is not loaded
		if (!searchConfig) {
			return {
				backendExperiment: undefined,
				loading: true,
			};
		}

		const dynamicExperiments = dynamicExperimentsSchema.safeParse(
			StatsigConfigurations.getStringList('search_platform_layer_functions', []),
		).data;

		if (!dynamicExperiments) {
			return {
				backendExperiment: undefined,
				loading: false,
			};
		}

		const siteMetadata = searchConfig?.siteMetadata.find((metadata) => metadata.siteId === cloudId);

		const isHello = siteMetadata?.siteId === HELLO_CLOUD_ID;
		const productEdition = siteMetadata?.productEdition;
		const productDataRegions = siteMetadata?.productDataRegions;

		const requestedEntities = entities.flatMap((entity) => {
			try {
				const { resourceOwner, resourceType } = Ati.parse(entity);
				return [{ entity: resourceOwner, subEntity: resourceType.toUpperCase() }];
			} catch {
				// TODO: Error handling with Confluence's sentry client
				return [];
			}
		});

		const backendExperiment = mapDynamicExperiments({
			dynamicExperiments,
			requestedEntities,
			productEdition,
			isHello,
			productDataRegions,
			experience,
		});

		return {
			backendExperiment,
			loading: false,
		};
	}, [cloudId, entities, experience, searchConfig]);
};
