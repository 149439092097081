import { z } from 'zod';

const experimentExperienceEnum = z.enum([
	'search-page',
	'quick-find',
	'search-page-space-filter',
	'quick-search',
	'advanced-search',
]);

export const dynamicConfigSchema = z
	.string()
	.transform((str, ctx) => {
		try {
			return JSON.parse(str);
		} catch (e) {
			ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
			return z.NEVER;
		}
	})
	.pipe(
		z.object({
			layerType: z.string().startsWith('search_platform_layer_').optional(),
			statsigLayerId: z.string().startsWith('search_platform_'),
			entity: z.string().optional(),
			subEntity: z.string().optional(),
			hello: z.boolean().optional(),
			confluenceTier: z.string().array().optional(),
			productDataRegions: z.record(z.string(), z.string().array()).optional(),
			experiences: experimentExperienceEnum.array().optional(),
		}),
	);

export type DynamicConfig = z.infer<typeof dynamicConfigSchema>;
export type ExperimentExperience = z.infer<typeof experimentExperienceEnum>;
export type ExperimentExperiences = z.infer<typeof dynamicConfigSchema>['experiences'];

export const dynamicExperimentsSchema = z
	.array(z.string())
	.transform((arrayJSONStrings) => {
		return arrayJSONStrings
			.map((jsonStr) => dynamicConfigSchema.safeParse(jsonStr).data)
			.flatMap((dynamicConfig) => (dynamicConfig ? dynamicConfig : []));
	})
	.refine((dynamicConfig) => dynamicConfig.length); // The schema is invalid if all JSON strings are invalid

export type DynamicExperiments = z.infer<typeof dynamicExperimentsSchema>;
